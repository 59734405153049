h1.errorCode:global(.ant-typography) {
  margin-bottom: 0;
  font-size: 8em;
  font-weight: 300;
  line-height: 0.75;
  letter-spacing: -0.05em;
  color: #666666;
}

h1.errorMessage:global(.ant-typography) {
  margin-bottom: 0;
  font-weight: 100;
  font-size: 1.6em;
  color: #666666;
}

.errorWrapper {
  background: snow;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.errorAnimation {
  max-width: 440px;
}

.buttonText {
  color: white;
}
